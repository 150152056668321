<template>
  <div>
    <backButton />
    <h3 class="mb-4">{{ $t("Settings") }}</h3>
    <v-row dense>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title>
            {{ $t("Add light background logo") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              :multiple="false"
              uploadUrl="/settings/upload-logo-pos/"
              uploadBtnName="Upload logo"
              :needsUploadBtn="true"
              :accepts="['.svg', '.png']"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title>
            {{ $t("Add dark background logo") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              :multiple="false"
              uploadUrl="/settings/upload-logo-neg"
              uploadBtnName="Upload logo"
              :needsUploadBtn="true"
              :accepts="['.svg', '.png']"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title>
            {{ $t("Add favicon") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              :multiple="false"
              uploadUrl="/settings/upload-favicon"
              uploadBtnName="Upload favicon"
              :needsUploadBtn="true"
              :accepts="['.ico']"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            {{ $t("Onboarding") }}
          </v-card-title>
          <v-card-text>
            <v-row
              dense
              v-for="(onboardingItem, index) in onboardingItems"
              :key="index"
            >
              <v-col cols="12">
                <v-layout justify-end align-center>
                  <v-switch
                    flat
                    :ripple="false"
                    v-model="isCustom[index]"
                    :label="$t('Is custom')"
                  ></v-switch>
                </v-layout>
              </v-col>
              <v-col cols="1">
                <v-layout column>
                  <v-btn
                    depressed
                    text
                    small
                    icon
                    color="grey"
                    @click="moveUp(index)"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>

                  <v-btn
                    depressed
                    text
                    small
                    icon
                    color="grey"
                    @click="moveDown(index)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
              <onboarding-cards
                :onboardingItem="onboardingItem"
                :isCustom="isCustom[index]"
              />

              <v-col cols="1" class="d-flex justify-end align-center">
                <v-btn depressed @click="deleteItem(index)" color="primaryred" icon>
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="index + 1 !== onboardingItems.length">
                <v-divider class="my-2"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-layout justify-start>
                  <v-btn
                    depressed
                    @click="addToOnboadingItems()"
                    color="primarygreen"
                    class="white--text"
                  >
                    {{ $t("Add onboarding") }}
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-end mt-4">
                <v-btn depressed @click="saveOnboading" color="primary">
                  {{ $t("Save onboarding") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DragAndDrop from "../shared/DragAndDrop.vue";

export default {
  data() {
    return {
      dragover: null,
      dragover2: null,
      dragover3: null,
      files: null,
      imagePos: {
        src: null,
        file: null,
      },
      imageNeg: {
        src: null,
        file: null,
      },
      favicon: {
        src: null,
        file: null,
      },
      config: this.$config,
      onboardingItems: [],
      onboardingCategory: null,
      textAreaOnboardingItem: [],
      isCustom: [],
    };
  },
  components: { DragAndDrop },

  created() {
    if (this.config.logoPos) {
      const toaster = {
        message: this.$t("Not available due to old config file"),
        timeout: false,
        type: "WARNING",
        toastClassName: ["toaster-color-warning", "toaster-layout"],
        bodyClassName: ["toaster-text-layout"],
        icon: "mdi-alert",
      };
      this.$store.dispatch("setToaster", toaster);
    }
    if (this.config.logoNeg) {
      const toaster = {
        message: this.$t("Not available due to old config file"),
        timeout: false,
        type: "WARNING",
        toastClassName: ["toaster-color-warning", "toaster-layout"],
        bodyClassName: ["toaster-text-layout"],
        icon: "mdi-alert",
      };
      this.$store.dispatch("setToaster", toaster);
    }
    this.$request.get("/onboardingsteps", null, (res) => {
      if (res.success) {
        this.onboardingItems = res.data;
        this.onboardingItems.forEach((element) => {
          for (const key in element.Components) {
            if (element.Components[key]) {
              this.isCustom[element.Sort] = true;
            }
          }
        });
      } else {
        const toaster = {
          message: res.message,
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    });
  },
  computed: {
    disablePos() {
      if (this.$config.logoPos || !this.imagePos.src) {
        return true;
      }
      return false;
    },
    disableNeg() {
      if (this.$config.logoNeg || !this.imageNeg.src) {
        return true;
      }
      return false;
    },

    getFullOnboardingArray() {
      this.onboardingItems.forEach((element, index) => {
        this.onboardingItems[index]["Sort"] = index;
      });
      return this.onboardingItems;
    },
    disableFav() {
      if (!this.favicon.src) {
        return true;
      }
      return false;
    },
  },
  methods: {
    moveUp(index) {
      if (index == 0) return;

      const oldValue = this.onboardingItems[index];
      const newValue = this.onboardingItems[index - 1];
      this.onboardingItems.splice(index, 1, newValue);
      this.onboardingItems.splice(index - 1, 1, oldValue);
      this.$set(this.isCustom, index - 1, !this.isCustom[index - 1]);
      this.$set(this.isCustom, index, !this.isCustom[index]);
    },
    moveDown(index) {
      if (index == this.onboardingItems.length - 1) return;

      const oldValue = this.onboardingItems[index];
      const newValue = this.onboardingItems[index + 1];

      this.onboardingItems.splice(index, 1, newValue);
      this.onboardingItems.splice(index + 1, 1, oldValue);
      this.$set(this.isCustom, index + 1, !this.isCustom[index + 1]);
      this.$set(this.isCustom, index, !this.isCustom[index]);
    },
    addToOnboadingItems() {
      this.$set(this.onboardingItems, this.onboardingItems.length, {
        Components: {},
        Images: {},
        Texts: {},
      });
      
      window.scrollBy(0, window.innerHeight);
    },

    saveOnboading() {
      const items = this.getFullOnboardingArray;
      this.$request.put("/onboardingsteps", items, (res) => {
        if (res.success) {
          const toaster = {
            message: this.$t("Onboarding was successfully saved"),
            timeout: false,
            type: "SUCCESS",
            toastClassName: ["toaster-color-success", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-check-circle",
          };
          this.$store.dispatch("setToaster", toaster);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    deleteItem(i) {
      this.$delete(this.isCustom, i);
      this.$delete(this.onboardingItems, i);
    },
  },
};
</script>